/* Globales y elementos */
p {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

/* Personaliza el estilo de las entradas y errores del formulario */
.strictly-input {
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  padding: 0.5rem 1rem;
}

.text-danger {
  font-size: 0.875rem;
}

/* Botones */
.button-flex {
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 600;
}

/* Estilo para el logo de QuickBooks */
.qb-logo {
  max-width: 64px;
  width: 100%;
  height: auto;
}

/* Cards */
.custom-card {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 1rem;
  background-color: #fff;
}

/* Alerts */
.custom-alert-danger {
  padding: 0.5rem !important;
}

/* Table */
.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 14px;
}

.table th,
.table td {
  padding: 8px 12px;
  border: 1px solid #e3e6e8;
}

.table thead {
  background-color: #f8f9fa;
}

.table tbody tr {
  background-color: #fff;
}

.table tbody tr:nth-child(even) {
  background-color: #f8f9fa;
}

.table tbody tr:hover {
  background-color: #e9ecef;
}

.table .text-start {
  text-align: left;
}

.table .text-end {
  text-align: right;
}

.table td:last-child {
  position: relative;
}

.table .action-column {
  display: flex;
  align-items: center;
  justify-content: center;
}

.actions-icon {
  cursor: pointer;
  color: #6c757d; /* Color del ícono */
}

.actions-icon:hover {
  color: #495057; /* Color del ícono al hacer hover */
}

.min-vh-100 {
  min-height: 100vh;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.view-only-mobile {
  display: none !important;
}

@media (max-width: 767.98px) {
  .d-flex {
    flex-direction: column !important;
  }

  .justify-content-center {
    justify-content: flex-start !important;
  }

  .view-only-mobile {
    display: flex !important;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }

  .view-only-desktop {
    display: none !important;
  }
  .custom-card {
    text-align: center;
  }
}
